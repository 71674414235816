import { faTag } from '@fortawesome/free-solid-svg-icons';
import { computed } from 'vue';

import { useStore } from 'vuex';
import { useI18n } from "@/utils/i18n";

import { localErrorToast } from '@/utils/error';

import { openEditModal, default as editModalComponent } from '@/components/EditModal.vue';

import _ from 'lodash';

export function useEditCaseStatusModal() {
  const i18n = useI18n();

  const store = useStore();

  //Always defined by the user and thus localized
  const existingStatusNames = computed(() => {
    return _.map(store.getters['customization/getStatuses'], (status) => {
      return _.get(status, 'descriptor');
    });
  });

  //Defines the order of the available fields and additional metadata
  const formTemplate = computed(() => {
    return function(modifiedStatus) {
      let existingDescriptors = existingStatusNames.value;
      if (modifiedStatus != null && modifiedStatus.descriptor != null) {
        existingDescriptors = _.without(existingDescriptors, modifiedStatus.descriptor);
      }

      return [
        {
          descriptor: 'general',
          isRootAttribute: true,
          fields: [
            { descriptor: 'descriptor', required: true, icon: faTag, iconIsFontAwesome: true, allowCustomValues: true, excludedValues: existingDescriptors },
            { descriptor: 'defaultClosed', type: 'bool', required: true, icon: faTag, iconIsFontAwesome: true }
          ],
        }
      ]
    }
  });

  const presetValues = computed(() => {
    return {
      defaultClosed: false
    }
  })

  const open = async function(status, viewMode = false) {
    return openEditModal(editModalComponent, {
      formTemplate: formTemplate.value(status),
      automaticLocalizationPrefix: 'case-status.attributes',
      viewMode,
      createTitle: i18n.$t('case-status.edit.title.create'),
      editTitle: i18n.$t('case-status.edit.title.edit'),
      viewTitle: i18n.$t('case-status.edit.title.view'),
      deleteText: i18n.$t('case-status.edit.delete.title'),
      deleteQuestion: i18n.$t('case-status.edit.delete.question'),
      deleteConfirmation: i18n.$t('case-status.edit.delete.confirmation'),
      deleteWaitTimeSeconds: 0,
      existingValues: status,
      presetValues: presetValues.value,
      keepEmptyRootAttributes: true,
      enableDelete: true
    }).then(async ({action, data}) => {
      if (action !== 'delete' && (data == null || Object.keys(data).length < 1)) return;
      let statusObject = (data == null) ? {} : {...data}; //Create a shallow copy to remove properties

      //Get the current statuses or an empty array, will get modified with new data
      let currentStatuses = _.cloneDeep(store.getters['customization/getStatuses']) || [];

      //Merge the new data into the old status object. 3 possible states:
      //It is not modifying an existing and is newly created
      //It is modifying an existing one and the desciptor stays the same -> Merge that object - No checks for duplicates necessary
      //It is modifying an existing one and the desciptor changes -> Merge that object, removing the old one, same action as above - Check for duplicates and throw an error if found
      if ((action === 'update' || action === 'delete') && status != null) {
        //It changes the descriptor, so check for duplicates
        let checkForDuplicates = (statusObject.descriptor != null && statusObject.descriptor != status.descriptor);

        currentStatuses = _.compact(_.map(currentStatuses, (currentStatus) => {
          let newStatus = currentStatus;
          
          if (currentStatus.descriptor != null) {
            //Same with new name encountered if we check for duplicates throw an error
            if (checkForDuplicates && currentStatus.descriptor == statusObject.descriptor) throw 'Duplicate descriptors are not allowed';
            //Same with the original name encountered replace this one
            if (currentStatus.descriptor == status.descriptor) {
              //If updating, set the new values
              if (action === 'update') _.assign(newStatus, statusObject);
              //If deleting, return an empty value that will get removed by compact
              else if (action === 'delete') return undefined;
              //TODO Warn user that this change will not move animals! Only continue, if confirmed
            }
          }
          if (newStatus.descriptor == null) throw 'Invalid case status. Descriptor is required';
          return newStatus;
        }));
      } else if (action === 'create') {
        if (statusObject.descriptor == null) throw 'Invalid case status. Descriptor is required';
        currentStatuses.push(statusObject);
      } else {
        throw 'No valid action';
      }

      return store.dispatch('customization/updateStatuses', currentStatuses).then((uploadStatus) => uploadStatus.key);
    }).catch((error) => {
      console.error(error);
      localErrorToast(i18n, error);
    });
  }

  return { open };
}
import { faTag, faMapPin } from '@fortawesome/free-solid-svg-icons';
import { computed } from 'vue';

import { useStore } from 'vuex';
import { useI18n } from "@/utils/i18n";

import { localErrorToast } from '@/utils/error';

import { openEditModal, default as editModalComponent } from '@/components/EditModal.vue';

import _ from 'lodash';

export function useEditLocationModal() {
  const i18n = useI18n();

  const store = useStore();

  //Always defined by the user and thus localized
  const existingLocationNames = computed(() => {
    return _.map(store.getters['customization/getLocations'], (location) => {
      return _.get(location, 'descriptor');
    });
  });

  //Defines the order of the available fields and additional metadata
  const formTemplate = computed(() => {
    return function(modifiedLocation) {
      let existingDescriptors = existingLocationNames.value;
      
      if (modifiedLocation != null && modifiedLocation.descriptor != null) {
        existingDescriptors = _.without(existingDescriptors, modifiedLocation.descriptor);
      }

      return [
        {
          descriptor: 'general',
          isRootAttribute: true,
          fields: [
            { descriptor: 'descriptor', required: true, icon: faTag, iconIsFontAwesome: true, allowCustomValues: true, excludedValues: existingDescriptors },
            { descriptor: 'spots', icon: faMapPin, iconIsFontAwesome: true, allowMultipleValues: true, allowCustomValues: true, isArray: true }, //TODO Test location without spots, how animals are added!
          ],
        }
      ]
    }
  });

  const open = async function(location, viewMode = false) {
    let mappedSpotsInLocation;
    if (location != null) {
      mappedSpotsInLocation = {
        ...location,
        spots: _.map(location.spots, 'descriptor')
      }
    }
    return openEditModal(editModalComponent, {
      formTemplate: formTemplate.value(location),
      automaticLocalizationPrefix: 'location.attributes',
      viewMode,
      createTitle: i18n.$t('location.edit.title.create'),
      editTitle: i18n.$t('location.edit.title.edit'),
      viewTitle: i18n.$t('location.edit.title.view'),
      deleteText: i18n.$t('location.edit.delete.title'),
      deleteQuestion: i18n.$t('location.edit.delete.question'),
      deleteConfirmation: i18n.$t('location.edit.delete.confirmation'),
      deleteWaitTimeSeconds: 0,
      existingValues: mappedSpotsInLocation,
      keepEmptyRootAttributes: true,
      enableDelete: true
    }).then(async ({action, data}) => {
      if (action !== 'delete' && (data == null || Object.keys(data).length < 1)) return;
      let locationObject = (data == null) ? {} : {...data, spots: _.map(data.spots, (spot) => ({ descriptor: spot }))}; //Create a shallow copy to remove properties

      //Get the current locations or an empty array, will get modified with new data
      let currentLocations = _.cloneDeep(store.getters['customization/getLocations']) || [];

      //Merge the new data into the old locations object. 3 possible states:
      //It is not modifying an existing and is newly created
      //It is modifying an existing one and the desciptor stays the same -> Merge that object - No checks for duplicates necessary
      //It is modifying an existing one and the desciptor changes -> Merge that object, removing the old one, same action as above - Check for duplicates and throw an error if found
      if ((action === 'update' || action === 'delete') && location != null) {
        //It changes the descriptor, so check for duplicates
        let checkForDuplicates = (locationObject.descriptor != null && locationObject.descriptor != location.descriptor);

        currentLocations = _.compact(_.map(currentLocations, (currentLocation) => {
          let newLocation = currentLocation;
          
          if (currentLocation.descriptor != null) {
            //Same with new name encountered if we check for duplicates throw an error
            if (checkForDuplicates && currentLocation.descriptor == locationObject.descriptor) throw 'Duplicate descriptors are not allowed';
            //Same with the original name encountered replace this one
            if (currentLocation.descriptor == location.descriptor) {
              //If updating, set the new values
              if (action === 'update') {
                //Reassign existing properties to preserve them!
                let currentSpots = _.keyBy(currentLocation.spots, 'descriptor');
                locationObject.spots = _.map(locationObject.spots, (spot) => {
                  let newSpot = {...spot};
                  if (spot.descriptor in currentSpots && currentSpots[spot.descriptor] != null) {
                    _.assign(newSpot, currentSpots[spot.descriptor]);
                  }
                  return newSpot;
                });
                //Then set the modified locationObject
                _.assign(newLocation, locationObject);
              }
              //If deleting, return an empty value that will get removed by compact
              else if (action === 'delete') return undefined;
              //TODO Warn user that this change will not move animals! Only continue, if confirmed
            }
          }
          if (newLocation.descriptor == null) throw 'Invalid location. Descriptor is required';
          return newLocation;
        }));
      } else if (action === 'create') {
        if (locationObject.descriptor == null) throw 'Invalid location. Descriptor is required';
        currentLocations.push(locationObject);
      } else {
        throw 'No valid action';
      }

      return store.dispatch('customization/updateLocations', currentLocations).then((uploadStatus) => uploadStatus.key);
    }).catch((error) => {
      console.error(error);
      localErrorToast(i18n, error);
    });
  }

  return { open };
}
<template id="move-horse-modal">
  <ion-page>
    <ion-header>
      <ion-toolbar>
        <ion-title>{{ i18n.$t('analysis.move_subject.title') }}</ion-title>
        <ion-buttons slot="end">
          <ion-button @click="closeModal()">{{ i18n.$t('default_interaction.close') }}</ion-button>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>
    <ion-content>
      <ion-list>
        <ion-item class="header" lines="full" v-if="currentAnimal != null && currentAnimal.instance != null">
          <ion-label>
            <h2>{{ i18n.$t('analysis.move_subject.new_spot') }}: <b>{{ currentAnimal.instance.name }}</b></h2>
            <h3 v-if="currentAnimal.instance.unique_identifier != null"># {{ currentAnimal.instance.unique_identifier }}</h3>
          </ion-label>
        </ion-item>
        <template v-for="([locationKey, location]) of sortLocationsAndSpots(animalsByLocation)" :key="locationKey">
          <template v-if="location.descriptor != null">
            <ion-item-divider class="location-label">{{ location.descriptor }}</ion-item-divider>
            <ion-item :class="isCurrentSpot(location, spot) ? 'current-spot' : ''" v-for="([spotKey, spot]) of sortLocationsAndSpots(location.spots)" :key="spotKey" lines="full" :detail="!isCurrentSpot(location, spot)" :button="!isCurrentSpot(location, spot)" @click="(!isCurrentSpot(location, spot)) ? selectSpot(location.descriptor, spot.descriptor) : null">
              <ion-label>
                <h2>{{spot.descriptor}}</h2>
                <p v-if="areAnimalsAtSpot(spot)" class="occupancy-status occupied">
                  <template v-if="!isCurrentSpot(location, spot)">
                    <span class="status-label">{{ i18n.$t('analysis.occupancy_status.occupied') }}</span>
                    <template v-for="(animal, animalIndex) in getAnimalsAtSpot(spot)" :key="animalIndex">
                      <span v-if="animalIndex > 0" class="info-label separator"> &amp; </span> <!-- Handles edge case of multiple animals in one spot -->
                      <span v-else class="info-label separator"> — </span>

                      <template v-if="animal.instance != null">
                        <span v-if="animal.instance.name != null" class="info-label">{{ animal.instance.name }}</span>
                        <span v-if="animal.instance.name != null && animal.instance.unique_identifier != null" class="info-label"> | </span> <!-- If both are provided separate them -->
                        <span v-if="animal.instance.unique_identifier != null" class="info-label"># {{ animal.instance.unique_identifier }}</span>
                      </template>
                    </template>
                  </template>
                  <span v-else class="status-label">{{ i18n.$t('analysis.occupancy_status.current_spot') }}</span>
                </p>
                <p v-else class="occupancy-status free">{{ i18n.$t('analysis.occupancy_status.free') }}</p>
              </ion-label>
            </ion-item>
          </template>
          <template v-else-if="!isCurrentSpot(location, {})">
            <ion-item-divider class="unset-location-label">{{ i18n.$t('analysis.subject.unassigned') }}</ion-item-divider>
            <ion-item lines="full" detail button @click="selectSpot(null, null)">
              <ion-label>
                <h2>{{ i18n.$t('analysis.move_subject.replacement.unassign') }}</h2>
              </ion-label>
            </ion-item>
          </template>
        </template>
        <!-- TODO Could do that for every location with edit return! But then which one? -->
<!--         <ion-item-divider class="unset-location-label">{{ i18n.$t('analysis.move_subject.create_spot.title') }}</ion-item-divider> 
        <ion-item lines="full" detail button @click="createNewSpot()">
          <ion-label>
            <h2>{{ i18n.$t('analysis.move_subject.create_spot.description') }}</h2>
          </ion-label>
        </ion-item> -->
      </ion-list>
    </ion-content>
  </ion-page>
</template>

<script>

import { IonPage, IonHeader, IonTitle, IonToolbar, IonButtons, IonButton, IonContent, IonList, IonItem, IonItemDivider, IonLabel, modalController, alertController } from '@ionic/vue';
import { computed, defineComponent, watch } from 'vue';

import { useI18n } from "@/utils/i18n";
import { getSortedEntriesBySortOrder } from '@/utils/report';

import _ from 'lodash';

const MoveHorseModal = defineComponent({
  name: 'MoveHorseModal',
  components: { IonPage, IonHeader, IonTitle, IonToolbar, IonButtons, IonButton, IonContent, IonList, IonItem, IonItemDivider, IonLabel },
  props: {
    'modalReadyPromise': Promise,
    'animalsByLocation': Object,
    'animalInstances': Object,
    'currentLocationDescriptor': String,
    'currentSpotDescriptor': String,
    'selectedLocation': Object,
    'currentAnimal': Object
  },
  setup(props) {
    const i18n = useI18n();

    const noValuePlaceholder = '—';

    const closeModal = function(){
      modalController.dismiss();
    }

    const sortLocationsAndSpots = function(locationsOrSpots) {
      return getSortedEntriesBySortOrder(locationsOrSpots, false, 'order');
    }

    const getAnimalWithId = computed(() => {
      return function(animalId) {
        return _.get(props.animalInstances, [animalId]);
      }
    });

    const getAnimalsAtSpot = computed(() => {
      return function(spot) {
        if (spot != null && spot.animals != null) {
          return _.map(spot.animals, (animalId) => {
            return {
              id: animalId,
              instance: getAnimalWithId.value(animalId)
            };
          });
        }

        return [];
      }
    });

    const getAnimalsAtPath = computed(() => {
      return function(location, spot) {
        return _.map(_.get(props.animalsByLocation, [location, 'spots', spot, 'animals']), (animalId) => {
          return {
            id: animalId,
            instance: getAnimalWithId.value(animalId)
          };
        });
      }
    });

    const areAnimalsAtSpot = computed(() => {
      return function(spot) {
        return getAnimalsAtSpot.value(spot).length > 0;
      }
    });

    const isCurrentSpot = computed(() => {
      return function(location, spot) {
        return (location.descriptor == props.currentLocationDescriptor && spot.descriptor == props.currentSpotDescriptor);
      }
    });

    const createNewSpot = function(){
      //Prepare change request for this animal
      let animalChanges = {};
      animalChanges[props.currentAnimal.id] = {new: true};

      return props.modalReadyPromise.then(() => modalController.dismiss(animalChanges));
    }

    const selectSpot = function(locationDescriptor, spotDescriptor) {
      if (locationDescriptor == props.currentLocationDescriptor && spotDescriptor == props.currentSpotDescriptor) {
        return;
      }
      //Prepare change request for this animal
      let animalChanges = {};
      animalChanges[props.currentAnimal.id] = {location: locationDescriptor, spot: spotDescriptor};

      //Remove the assignment of a spot
      if (locationDescriptor == null || spotDescriptor == null) {
        animalChanges[props.currentAnimal.id] = {location: null, spot: null};
        return props.modalReadyPromise.then(() => modalController.dismiss(animalChanges));
      }

      let animalsAtSpot = getAnimalsAtPath.value(locationDescriptor, spotDescriptor);

      if (animalsAtSpot.length == 0) {
        return props.modalReadyPromise.then(() => modalController.dismiss(animalChanges));
      }
      
      let animalIDs = '';
      let animalNames = '';
      for (let animalIndex = 0; animalIndex < animalsAtSpot.length; animalIndex++) {
        if (animalIndex > 0) {
          animalIDs += ', ';
          animalNames += ' & ';
        }

        if (_.get(animalsAtSpot, [animalIndex, 'instance', 'unique_identifier']) != null) {
          animalIDs += `# ${animalsAtSpot[animalIndex].instance.unique_identifier}`;
        } else {
          animalIDs += `# ${noValuePlaceholder}`;
        }

        if (_.get(animalsAtSpot, [animalIndex, 'instance', 'name']) != null) {
          animalNames += `${animalsAtSpot[animalIndex].instance.name}`;
        } else {
          animalNames += noValuePlaceholder;
        }
      }

      //If there is an animal in the chosen spot, ask for how to handle it - Replace or move to unassigned
      return alertController
        .create({
          backdropDismiss: false,
          header: `${locationDescriptor} | ${spotDescriptor}`,
          subHeader: `${i18n.$t('analysis.move_subject.replacement.title')}: ${animalNames} | ${animalIDs}`,
          message: i18n.$t('analysis.move_subject.replacement.question'),
          buttons: _.compact([
            ((props.currentLocationDescriptor == null || props.currentSpotDescriptor == null) ? undefined : ({ //Swapping is no option when it comes from unassigned
              text: i18n.$t('analysis.move_subject.replacement.replace'),
              cssClass: 'replace-warning',
              handler: () => {
                //Fallback to change multiple animals
                for (let animal of animalsAtSpot) {
                  animalChanges[animal.id] = {location: props.currentLocationDescriptor, spot: props.currentSpotDescriptor}; //Set the location of the current one to swap
                }
                props.modalReadyPromise.then(() => modalController.dismiss(animalChanges));
              }
            })),
            {
              text: i18n.$t('analysis.move_subject.replacement.unassign'),
              cssClass: 'unassign-okay',
              handler: () => {
                //Fallback to change multiple animals
                for (let animal of animalsAtSpot) {
                  animalChanges[animal.id] = {location: null, spot: null}; //Unassign the location of the animal in the chosen spot
                }
                props.modalReadyPromise.then(() => modalController.dismiss(animalChanges));
              }
            },
            {
              text: i18n.$t('default_interaction.cancel'),
              role: 'cancel'
            }
          ]),
        })
        .then(a => a.present())
    }

    watch(() => props.selectedLocation, (newSelectedLocation, oldSelectedLocation) => {
      if (newSelectedLocation != null && !(_.isEqual(newSelectedLocation, oldSelectedLocation))) selectSpot(newSelectedLocation.location, newSelectedLocation.spot);
    }, { immediate: true });

    return { i18n, closeModal, sortLocationsAndSpots, isCurrentSpot, selectSpot, createNewSpot, getAnimalsAtSpot, areAnimalsAtSpot };
  }
});

export async function openMoveHorseModal(component, animalsByLocation, animalInstances, currentAnimal, {location, spot, selectUUID}){
  if (component != null && animalsByLocation != null && animalInstances != null && currentAnimal != null) {
    //Create a promise to signal the component when the modal is ready to initialize view elements
    let modalReady;
    const modalReadyPromise = new Promise((resolve) => modalReady = resolve);

    let selectedLocation;
    if (selectUUID != null) {
      _.forEach(animalsByLocation, (location, locationDescriptor) => {
        _.forEach(location.spots, (spot, spotDescriptor) => {
          if (spot.uuid != null && selectUUID == spot.uuid) {
            selectedLocation = { location: locationDescriptor, spot: spotDescriptor };
          }
        });
      });
    } //TODO Maybe ask for confirmation in location and status change if it is preselected and otherwise would not require confirmation! Then it's also not a problem that the modal stays open!

    const modal = await modalController
      .create({
        component,
        componentProps: {
          modalReadyPromise,
          animalsByLocation,
          animalInstances,
          currentLocationDescriptor: location,
          currentSpotDescriptor: spot,
          selectedLocation,
          currentAnimal
        }
      })
    const presentPromise = modal.present();
    presentPromise.then(() => modalReady());
    return modal.onWillDismiss();
  }
}

export default MoveHorseModal;
</script>

<style>
.replace-warning {
  color: var(--ion-color-warning)!important;
}

.unassign-okay {
  color: var(--ion-color-success-shade)!important;
}
</style>

<style scoped>
ion-item ion-label * {
  white-space: normal;
  word-wrap: break-word;
  overflow-wrap: break-word;
}

ion-content {
  --background: var(--ion-item-background, var(--ion-background-color, #fff));
}

.header {
  text-align: center;
}

.header h2 {
  font-size: 1.2em;
}

.location-label, .unset-location-label {
  font-size: 1.25em;
  padding-top: 5px;
  padding-bottom: 5px;
  color: var(--ion-color-primary-text);
}

.location-label {
  font-weight: 600;
}

.unset-location-label {
  font-style: italic;
}

.occupancy-status.free {
  color: var(--ion-color-success-shade);
}

.occupancy-status.occupied .status-label {
  color: var(--ion-color-danger);
}

.current-spot .occupancy-status.occupied .status-label {
  color: var(--ion-color-primary-text);
}

.info-label:not(.separator) {
  font-weight: 500;
  color: var(--ion-color-dark);
}
</style>
<template id="change-status-modal">
  <ion-page>
    <ion-header>
      <ion-toolbar>
        <ion-title>{{ i18n.$t('analysis.change_status.title') }}</ion-title>
        <ion-buttons slot="end">
          <ion-button @click="closeModal()">{{ i18n.$t('default_interaction.close') }}</ion-button>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>
    <ion-content>
      <ion-list>
        <ion-item class="header" lines="full" v-if="currentAnimal != null && currentAnimal.instance != null">
          <ion-label>
            <h2>{{ i18n.$t('analysis.change_status.new_status') }}: <b>{{ currentAnimal.instance.name }}</b></h2>
            <h3 v-if="currentAnimal.instance.unique_identifier != null"># {{ currentAnimal.instance.unique_identifier }}</h3>
          </ion-label>
        </ion-item>
        <template v-for="([statusKey, status]) of sortStatuses(animalsByStatus)" :key="statusKey">
          <template v-if="status.descriptor != null">
            <ion-item :class="['status-item', isCurrentStatus(status) ? 'current-status' : undefined]" lines="full" :detail="!isCurrentStatus(status)" :button="!isCurrentStatus(status)" @click="(!isCurrentStatus(status)) ? selectStatus(status.descriptor) : null">
              <ion-label>
                <h2>{{status.descriptor}}</h2>
                <p v-if="areAnimalsInStatus(status)" class="occupancy-status occupied">
                  <template v-if="!isCurrentStatus(status)">
                    <span class="status-label">{{ i18n.$t('analysis.occupancy_status.animals_in_status') }}:</span>
                    <span class="info-label">{{ getAnimalsInStatus(status).length }}</span>
                  </template>
                  <span v-else class="status-label">{{ i18n.$t('analysis.occupancy_status.current_status') }}</span>
                </p>
                <p v-else class="occupancy-status free">{{ i18n.$t('analysis.occupancy_status.no_animals_status') }}</p>
              </ion-label>
            </ion-item>
          </template>
          <template v-else-if="!isCurrentStatus(status)">
            <ion-item-divider class="unset-status-label">{{ i18n.$t('analysis.subject.not_present') }}</ion-item-divider>
            <ion-item lines="full" detail button @click="selectStatus(null)">
              <ion-label>
                <h2>{{ i18n.$t('analysis.change_status.archive') }}</h2>
              </ion-label>
            </ion-item>
          </template>
        </template>
        <!-- TODO Could do that for every status with edit return! But then which one? -->
<!--         <ion-item-divider>{{ i18n.$t('analysis.change_status.create.title') }}</ion-item-divider> 
        <ion-item lines="full" detail button @click="createNewStatus()">
          <ion-label>
            <h2>{{ i18n.$t('analysis.change_status.create.description') }}</h2>
          </ion-label>
        </ion-item> -->
      </ion-list>
    </ion-content>
  </ion-page>
</template>

<script>

import { IonPage, IonHeader, IonTitle, IonToolbar, IonButtons, IonButton, IonContent, IonList, IonItem, IonItemDivider, IonLabel, modalController } from '@ionic/vue';
import { computed, defineComponent, watch } from 'vue';

import { useI18n } from "@/utils/i18n";
import { getSortedEntriesBySortOrder } from '@/utils/report';

import _ from 'lodash';

const ChangeStatusModal = defineComponent({
  name: 'ChangeStatusModal',
  components: { IonPage, IonHeader, IonTitle, IonToolbar, IonButtons, IonButton, IonContent, IonList, IonItem, IonItemDivider, IonLabel, },
  props: {
    'modalReadyPromise': Promise,
    'animalsByStatus': Object,
    'animalInstances': Object,
    'currentStatusDescriptor': String,
    'selectedStatus': String,
    'currentAnimal': Object
  },
  setup(props) {
    const i18n = useI18n();

    const closeModal = function(){
      modalController.dismiss();
    }

    const sortStatuses = function(statuses) {
      return getSortedEntriesBySortOrder(statuses, false, 'order');
    }

    const getAnimalsInStatus = function(status) {
      if (status != null && status.animals != null) {
        return status.animals;
      }

      return [];
    }

    const areAnimalsInStatus = function(status) {
      return getAnimalsInStatus(status).length > 0;
    }

    const isCurrentStatus = computed(() => {
      return function(status) {
        return (status.descriptor == props.currentStatusDescriptor);
      }
    });

    const createNewStatus = function(){
      //Prepare change request for this animal
      let animalChanges = {};
      animalChanges[props.currentAnimal.id] = {new: true};

      return props.modalReadyPromise.then(() => modalController.dismiss(animalChanges));
    }

    const selectStatus = function(statusDescriptor) {
      if (statusDescriptor == props.currentStatusDescriptor) {
        return;
      }
      //Prepare change request for this animal
      let animalChanges = {};
      animalChanges[props.currentAnimal.id] = {status: statusDescriptor};

      //Remove the assignment of a status
      if (statusDescriptor == null) {
        animalChanges[props.currentAnimal.id] = {status: null};
        return props.modalReadyPromise.then(() => modalController.dismiss(animalChanges));
      }

      return props.modalReadyPromise.then(() => modalController.dismiss(animalChanges));
    }

    watch(() => props.selectedStatus, (newSelectedStatus, oldSelectedStatus) => {
      if (newSelectedStatus != null && newSelectedStatus != oldSelectedStatus) selectStatus(newSelectedStatus);
    }, { immediate: true });

    return { i18n, closeModal, sortStatuses, isCurrentStatus, selectStatus, createNewStatus, getAnimalsInStatus, areAnimalsInStatus };
  }
});

export async function openChangeStatusModal(component, animalsByStatus, animalInstances, currentAnimal, {status, selectUUID}){
  if (component != null && animalsByStatus != null && animalInstances != null &&  currentAnimal != null) {
    //Create a promise to signal the component when the modal is ready to initialize view elements
    let modalReady;
    const modalReadyPromise = new Promise((resolve) => modalReady = resolve);

    let selectedStatus;
    if (selectUUID != null) {
      _.forEach(animalsByStatus, (status, statusDescriptor) => {
        if (status.uuid != null && selectUUID == status.uuid) {
          selectedStatus = statusDescriptor;
        }
      });
    }

    const modal = await modalController
      .create({
        component,
        componentProps: {
          modalReadyPromise,
          animalsByStatus,
          animalInstances,
          currentStatusDescriptor: status,
          selectedStatus,
          currentAnimal
        }
      })
    const presentPromise = modal.present();
    presentPromise.then(() => modalReady());
    return modal.onWillDismiss();
  }
}

export default ChangeStatusModal;
</script>

<style>
.replace-warning {
  color: var(--ion-color-warning)!important;
}

.unassign-okay {
  color: var(--ion-color-success-shade)!important;
}
</style>

<style scoped>
ion-item ion-label * {
  white-space: normal;
  word-wrap: break-word;
  overflow-wrap: break-word;
}

ion-content {
  --background: var(--ion-item-background, var(--ion-background-color, #fff));
}

.header {
  text-align: center;
}

.header h2 {
  font-size: 1.2em;
}

.status-item h2 {
  font-size: 1.25em;
  color: var(--ion-color-tertiary-text);
  font-weight: bold;
}

.unset-status-label {
  font-style: italic;
}

.occupancy-status.free {
  color: var(--ion-color-success-shade);
}

.occupancy-status.occupied {
  color: var(--ion-color-step-950, #595959);
}

.current-status .occupancy-status.occupied .status-label {
  color: var(--ion-color-tertiary-text);
}
</style>